<template>
  <div class="recharge-page">
    <!--金额-->
    <div class="money-list df fdr fww jc-sb">
      <div class="money-item df alc jcc" :class="{active: index===current_select}" v-for="(i,index) in money_list"
           :key="index" @click="selectMoney(i,index)">
        {{i}}元
      </div>
    </div>
    <!--输入-->
    <div class="any-box">
      <yd-input type="number" slot="right" v-model="any_money" required :show-success-icon="false"
                :show-error-icon="false"
                placeholder="请输入任意金额"></yd-input>
    </div>
    <div @click.stop="rechargeAccount">
      <MyButton title="立即支付" :is_disabled="is_disabled"></MyButton>
    </div>
    <div class="agreement-box" v-show="show_agreement">充值即为同意<span class="agreement">《壹企通充值协议》</span></div>
  </div>
</template>

<script>
import MyButton from '@/components/button/button'
import {wxShare}  from "@/utils/common";
import {rechargeAccount} from '@/services/my'
import {baseUrl} from "@/utils/env";

let bid = window.localStorage.getItem('bid');

export default {
  name: "recharge",
  components: {
    MyButton
  },
  data() {
    return {
      money_list: [100, 200, 500, 1000, 3000, 5000],
      any_money: '',
      money: '',
      is_disabled: false,
      current_select: -1,
      dom_height: document.documentElement.clientHeight,  //默认屏幕高度
      show_height: document.documentElement.clientHeight,   //实时屏幕高度
      show_agreement: true  //显示或者隐藏agreement
    }
  },
  methods: {
    toPay() {
      this.$router.push({
        name: 'recharge_success'
      })
    },
    //充值支付
    async rechargeAccount() {
      let money = this.money;
      if (this.any_money)
        money = this.any_money;
      console.log(money);
      if(!money){
        this.$dialog.toast({
          mes: "请输入或者选择充值金额",
        });
        return;
      }
      try {
        const res = await rechargeAccount(money);
        if (res.code === 0) {
          const data = res.data;
          const self = this;
          wx.chooseWXPay({
            timestamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
            package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: data.paySign, // 支付签名
            success: function (res) {
              // this.toPay()
              console.log(res);
              window.location.replace(`${baseUrl}/?bid=${bid}#/recharge_success?money=${money}`);
            },
            fail: function (res) {
              console.log(res);
              window.location.replace(`${baseUrl}/?bid=${bid}#/recharge_success?money=${money}`);
            }
          });
        }
      } catch (e) {

      }
    },
     //路由改变jssdk参数重新赋值
    beforeRouteEnter(to, from, next) {
      next();
      wxShare();
    },
    selectMoney(item, index) {
      if (this.current_select === index){
        this.current_select = -1;
        this.money = ''
      }
      else {
        this.current_select = index;
        this.money = item
      }
    },
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.show_height = document.body.clientHeight;
      })()
    }
  },
  watch: {
    show_height() {
      if (this.dom_height > this.show_height) {
        this.show_agreement = false
      } else {
        this.show_agreement = true
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.recharge-page {


  .money-item, .active {
    .wh(3.4rem, 1.76rem);
    border-radius: 0.08rem;
  }

  .money-list {
    padding: 0.24rem 0.22rem 0 0.24rem;

    .money-item {
      background: @color-white;
      .sc(0.48rem, #333);
      margin-bottom: 0.24rem;
    }

    .active {
      background: var(--main-color);
      .sc(0.48rem, @color-white);


    }
  }


  .any-box {
    .sc(0.32rem, #333);
    background: @color-white;
    .wh(100%, 1rem);
    padding: 0 0.24rem;
    margin-top: 0.16rem;
    margin-bottom: 1.88rem;

    input::placeholder {
      .sc(0.32rem, @color-light-grey);
    }

  }

  .agreement-box {
    .sc(0.28rem, @color-light-grey);
    position: absolute;
    bottom: 0.24rem;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;

    .agreement {
      color: #333;
    }

  }
}
</style>